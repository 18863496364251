import React from 'react';
import { graphql } from 'gatsby';
import { Link } from 'gatsby';
import styled from '@emotion/styled';
import colors from 'styles/colors';
import dimensions from 'styles/dimensions';
import { Inner } from 'styles/structure';
import Layout from 'components/Layout';
import SEO from 'components/SEO';
import Content from 'components/_ui/Content';
import Related from 'components/Related';
import IconQuote from 'vectors/quote.svg';
import Caret from 'vectors/caret-right.svg';

const BioContainer = styled.div`
  padding-top: 3.2rem;
  margin-bottom: 4.8rem;
  
  @media (min-width: ${dimensions.tabletLandscapeUp}px) {
    margin-bottom: 8.8rem;
  }
`;

const BackLink = styled(Link)`
  display: none;
  position: relative;
  padding: 0.8rem 0 0.8rem 7.2rem;
  margin-bottom: 5.6rem;
  
  span {
    color: ${colors.grey700};
    font-weight: 400;
    font-size: 1.6rem;
    font-weight: 500;
  }
  
  div {
    position: absolute;
    right: calc(100% - 4.8rem);
    // visual centering
    top: 42%;
    width: 4.8rem;
    height: 0.2rem;
    background-color: ${colors.red600};
    transition: width 0.12s ease-in-out;
  }
  
  svg {
    display: block;
    position: absolute;
    left: -0.2rem;
    top: 50%;
    transform: translateY(-50%) rotate(180deg);
    width: 0.9rem;
    height: 1.7rem;
    fill: ${colors.red600};
  }
  
  @media (min-width: ${dimensions.tabletLandscapeUp}px) {
    display: inline-block;
  }
`;

const BioBody = styled.div`
  @media (min-width: ${dimensions.tabletLandscapeUp}px) {
    display: flex;
  }
`;

const BioImage = styled.div`
  img {
    display: block;
    width: 100%;
    max-width: 59.4rem;
  }
  
  & + * {
    margin-top: 1.6rem;
  }
  
  @media (min-width: ${dimensions.tabletLandscapeUp}px) {
    flex: 1 1 59.4rem;
    margin-right: 7.2rem;
    
    img {
      & + * {
        margin-top: 8.8rem;
      }
    }
    
    & + * {
      margin-top: 0;
    }
  }
`;

const BioContent = styled.div`
  header {
    position: relative;
    margin-bottom: 3.2rem;
    padding-bottom: 1.6rem;
    
    &::after {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      height: 0.3rem;
      width: 100vw;
      background-color: ${colors.red600};
    }
    
    h2 {
      font-weight: 400;
    }
  }
  
  @media (min-width: ${dimensions.tabletLandscapeUp}px) {
    flex: 2 1 66.6rem;
    
    header {
      margin-bottom: 4.8rem;
      padding-bottom: 4rem;  
    }
    
    h2 {
      margin-bottom: 0.8rem;
    }
  }
`;

const BioQuote = styled.div`  
  margin-bottom: 6.4rem;
  
  h4 {
    font-weight: 400;
  }
  
  svg {
    display: block;
    width: 4.2rem;
    height: 2.9rem;
    fill: ${colors.red600};
    margin-bottom: 1.6rem;
  }
  
  &.BioQuote--desktop {
    display: none;
  }
  
  @media (min-width: ${dimensions.tabletLandscapeUp}px) {
    margin-bottom: 0;
    padding: 0 5vw;
    
    svg {
      width: 6.8rem;
      height: 4.8rem;
      margin-bottom: 2.4rem;
    }
    
    &.BioQuote--mobile {
      display: none;
    }
    
    &.BioQuote--desktop {
      display: block;
    }
  }
  
  @media (min-width: ${dimensions.desktopUp}px) {
    padding: 0 7.2rem;
  }
`;

const RenderBody = ({ bio, about, history }) => (
  <>
    <BioContainer>
      <Inner>
        <BackLink to='leadership'>
          <div>
            <Caret/>
          </div>

          <span>Back to Leadership</span>
        </BackLink>

        <BioBody>
          {bio.image && (
            <BioImage>
              <img
                src={bio.image.url}
                alt={bio.image.alt || bio.name}
              />

              {bio.quote && (
                <BioQuote className='BioQuote--desktop'>
                  <IconQuote />

                  <h4>{bio.quote}</h4>
                </BioQuote>
              )}
            </BioImage>
          )}

          <BioContent>
            {bio.name && (
              <header>
                <h2>{bio.name}</h2>
                {bio.title && <p>{bio.title}</p>}
              </header>
            )}

            {bio.quote && (
              <BioQuote className='BioQuote--mobile'>
                <IconQuote />

                <h4>{bio.quote}</h4>
              </BioQuote>
            )}

            {bio.content && <Content content={bio.content}/>}
          </BioContent>
        </BioBody>
      </Inner>
    </BioContainer>

    <Related
      heading='Learn more about us'
      type='trapdoor'
      cards={[
        {
          destination: '/about',
          image: about.feature_image,
          name: 'About',
          description: about.short_description,
        },
        {
          destination: '/history',
          image: history.feature_image,
          name: 'History',
          description: history.short_description,
        },
      ]}
    />
  </>
);

export default ({ data }) => {
  const bio = data.prismic.bioByUID;
  const about = data.prismic.allAbouts.edges[0].node;
  const history = data.prismic.allHistorys.edges[0].node;

  if (!bio) return null;

  return (
    <Layout>
      <SEO
        title={bio.name ? bio.name : bio.social_title || 'Bio'}
        description={bio.social_description ? bio.social_description : null}
        image={bio.social_image ? bio.image.url : null}
      />

      <RenderBody
        bio={bio}
        about={about}
        history={history}
      />
    </Layout>
  )
}

export const query = graphql`
  query BioQuery($uid: String!) {
    prismic {
      bioByUID: person(uid: $uid, lang: "en-us") {
        name
        title
        image
        quote
        content
        social_title
        social_description
        social_image
      }
      allAbouts {
        edges {
          node {
            feature_image
            short_description
          }
        }
      }
      allHistorys {
        edges {
          node {
            feature_image
            short_description
          }
        }
      }
    }
  }
`;
